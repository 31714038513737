<template>
  <div class="auth-wrapper">
    <div class="container">
      <div class="row auth-container">
        <div class="col-lg-6 col-md-12 auth-container-left">
          <a href="/">
            <img
              alt=""
              class="img-fluid"
              src="../../assets/images/logo/logo_register.svg"
            />
          </a>
        </div>
        <div class="col-lg-6 col-md-12 auth-container-right">
          <div class="auth-card">
            <div class="card-body">
              <notification-container />
              <BaseAlert
                v-if="error"
                :message="error"
                type="danger"
                @closeAlert="error = null"
              />
              <h1>{{ $t("auth.menu.register") }}</h1>
              <form
                v-if="!showSuccessElement"
                class="form m-t-30"
                @submit.prevent="handleRegisterForm"
              >
                <be-form-error
                  v-if="$v.$anyError"
                  :message="$t('auth.fields.required')"
                />
                <BaseInput
                  v-model="user.email"
                  :errorMessage="emailErrorMessage"
                  :isInvalid="
                    $v.user.email.$error || errors.email !== undefined
                  "
                  :placeholder="$t('auth.fields.email.placeholder')"
                  leftIconClass="icon-user"
                  type="email"
                  @blur="$v.user.email.$touch()"
                />
                <BaseInputPassword
                  v-model="user.password"
                  :errorMessage="passwordErrorMessage"
                  :isInvalid="
                    $v.user.password.$error || errors.password !== undefined
                  "
                  :placeholder="$t('auth.fields.password.placeholder')"
                  leftIconClass="icon-lock"
                  @blur="$v.user.password.$touch()"
                />
                <BaseInputPassword
                  v-model="user.password_confirmation"
                  :errorMessage="passwordConfirmErrorMessage"
                  :isInvalid="$v.user.password_confirmation.$error"
                  :placeholder="$t('auth.fields.confirm_password.placeholder')"
                  leftIconClass="icon-lock"
                  @blur="$v.user.password_confirmation.$touch()"
                />
                <be-multiselect
                  v-model="user.country_id"
                  :close-on-select="true"
                  :errorMessage="countryErrorMessage"
                  :isInvalid="$v.user.country_id.$error"
                  :options="countries"
                  :placeholder="$t('auth.fields.country.placeholder')"
                  class="m-t-30"
                  icon="icon-globe"
                  label="name"
                  onlyKey
                  track-by="id"
                  @blur="$v.user.country_id.$touch()"
                />

                <div class="form-group">
                  <small class="text-muted signup-text">
                    {{ $t("auth.register_me_desc_before") }}
                    <a href="#">{{ $t("auth.user_conditions") }}</a>
                    {{ $t("auth.register_me_desc_after") }}
                  </small>

                  <BaseButton
                    :disabled="disabledSubmitBtn"
                    :loading="loading"
                    :showLoading="true"
                    button-class="be-btn blue primary font-weight-bold w-100"
                    type="submit"
                  >
                    {{ $t("auth.register_me") }}
                  </BaseButton>
                  <p class="mb-0">
                    {{ $t("auth.did_you_have_an_account") }}
                    <router-link :to="{ name: 'Login' }"
                      >{{ $t("auth.menu.log_in") }}
                    </router-link>
                  </p>
                </div>
              </form>
              <div v-else>
                <img
                  class="success-image"
                  src="../../assets/images/success.png"
                />
                <div>
                  <p class="successful-title">
                    {{ $t("auth.register_successful") }}
                  </p>
                  <p class="successful-desc">
                    {{ $t("auth.register_successful_desc") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/layouts/Footer";
import { mapState } from "vuex";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import { recaptchaMixin } from "@/mixins/recaptchaMixin";
import NotificationContainer from "../../components/notification/NotificationContainer";
import BeMultiselect from "../../components/common/BeMultiselect";
import BeFormError from "../../components/common/BeFormError";

export default {
  name: "Register",
  mixins: [recaptchaMixin],
  components: { NotificationContainer, Footer, BeFormError, BeMultiselect },
  beforeMount() {
    this.$store
      .dispatch("getCountries")
      .then(response => {
        console.log(response);
      })
      .catch(response => {
        console.log(response);
      });
  },
  computed: {
    ...mapState(["countries"]),
    disabledSubmitBtn() {
      return this.$v.$invalid || this.loading || this.recaptchaToken === null;
    },
    emailErrorMessage() {
      if (this.$v.user.email.$error || this.errors.email) {
        if (!this.$v.user.email.required) {
          return this.$t("auth.errors.email.required");
        }
        if (!this.$v.user.email.email) {
          return this.$t("auth.errors.email.email");
        }
        if (this.errors.email) {
          return this.errors.email[0];
        }
      }
      return "";
    },
    passwordErrorMessage() {
      if (this.$v.user.password.$error || this.errors.password) {
        if (!this.$v.user.password.required) {
          return this.$t("auth.errors.password.required");
        }
        if (!this.$v.user.password.minLength) {
          return this.$t("auth.errors.password.min_length");
        }
        if (!this.$v.user.password.containsUppercase) {
          return this.$t("auth.errors.password.contains_uppercase");
        }
        if (!this.$v.user.password.containsNumber) {
          return this.$t("auth.errors.password.contains_number");
        }
        if (this.errors.password) {
          return this.errors.password[0];
        }
      }
      return "";
    },
    passwordConfirmErrorMessage() {
      if (this.$v.user.password_confirmation.$error) {
        if (!this.$v.user.password_confirmation.required) {
          return this.$t("auth.errors.password_confirmation.required");
        }
        if (!this.$v.user.password_confirmation.sameAsPassword) {
          return this.$t("auth.errors.password_confirmation.same_as_password");
        }
      }
      return "";
    },
    countryErrorMessage() {
      if (this.$v.user.country_id.$error) {
        if (!this.$v.user.country_id.required) {
          return this.$t("auth.errors.country.required");
        }
      }
      return "";
    },
  },

  data() {
    return {
      loading: false,
      user: {
        country_id: null,
        email: null,
        password: null,
        password_confirmation: null,
        recaptcha: null,
      },
      errors: [],
      error: null,
      showSuccessElement: false,
    };
  },

  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`),
    };
  },

  validations: {
    user: {
      email: { required, email },
      country_id: { required },
      password: {
        required,
        minLength: minLength(6),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value) === true;
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value) === true;
        },
      },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },
  beforeDestroy() {
    if (this.tm) {
      clearTimeout(this.tm);
    }
  },
  methods: {
    handleRegisterForm() {
      this.$v.$touch();
      if (!this.$v.invalid) {
        this.loading = true;
        this.error = null;
        this.user.recaptcha = this.recaptchaToken;
        this.$store
          .dispatch("auth/register", this.user)
          .then(() => {
            this.showSuccessElement = true;
          })
          .catch(err => {
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
            if (err.response.data.message)
              this.error = err.response.data.message;
            this.loadRecaptcha();
          })
          .finally(() => {
            this.loading = false;
            if (this.showSuccessElement) {
              if (this.tm) {
                clearTimeout(this.tm);
              }
              this.tm = setTimeout(() => {
                this.$router.push({ name: "EmailVerify" });
              }, 5000);
            }
          });
      }
    },
  },
};
</script>

<style scoped></style>
